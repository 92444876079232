<template>
    <div class="container_sign_up" style="height: auto;">
        <Navbar/>
        <div class="container_account_confirmation py-5">
            <b-container>
                <b-row align-h="center">
                    <b-col cols="12" lg="5" class="py-5">
                        <h1 class="mt-5 text-center fw-bold">
                            Thank You For Signing Up!
                        </h1>
                        
                        <p class="m-0 mt-5 text-center container_account_confirmation__back_to_login">
                            Do you have a verified account? &nbsp; <router-link :to="{ name: 'login' }" class="text-decoration-none text-white fw-bold">Back to login</router-link>
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Navbar from '@/components/Auth/Navbar';
import Footer from '@/components/Footer.vue';

export default {
    name: 'Accountconfirmation',
    components: { 
        Navbar,
        Footer
    },
    beforeCreate(){
        this.$http.post('verify/account', {token: this.$route.params.token}).then((response) => { }, (error) => {
            if (error.status == 419 || error.status == 400) {
                this.$router.push({ name: 'page.419' });
            }
            if (error.status == 500) {
                this.$notify({ group: 'notifications_home', type: 'error', text: 'An error has occurred, please try again.', duration: 5000 });
            }
        });
    },
};
</script>